<template>
  <div>
    <div class="pa-4 white radius-15">
      <future-job
        :info="profileNew"
        :completeProfile="true"
        @nextHandler="$emit('nextHandler',6)"
      ></future-job>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FutureJob from "../../../dashboard/components/dialogs/FutureJob.vue";
export default {
  components: { FutureJob },
  computed: {
    ...mapGetters(["profileNew"]),
  },
  methods: {},
};
</script>

<style></style>
